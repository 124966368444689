.header {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 72px;
	padding: 0;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
	z-index: 50;
	display: flex;
	background: #FFFFFF;
	color: #000000;
	align-items: center;
	justify-content: space-between;
}

.logo {
	height: 52px;
	width: auto;
	margin: 0 24px;
}

.header > nav {
	margin-right: 24px;
	height: 52px;
	display: flex;
	align-items: flex-end;
}

.header > nav a {
	display: inline-block;
	text-align: center;
	text-decoration: none;
	color: #000000;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;
	padding: 9px 24px;
	border: 1px solid #FFFFFF;
}

.header > nav a:hover,
.header > nav a:active {
	color: #000000;
}

.header > nav a.active {
	border-color: #B36631;
}

.header nav .contacts {
	background: #B36631;
	color: #FFFFFF;
	margin-left: 24px;
	border-color: #B36631;
}

.menuButtonContainer {
	float: right;
	display: none;
}

.menuContainer {
	display: none;
	position: absolute;
}

.menuContainer .menuBackdrop {
	position: fixed;
	top: 0;
	left: 0;
	margin-top: 72px;
	background: rgba(0,0,0,0.2);
	width: 100%;
	height: 100%;
	z-index: 47;
}

@media only screen and (max-width: 1310px) {
	.header > nav {
		display: none;
	}

	.menuButtonContainer {
		display: block;
	}

	.menuContainer {
		display: block;
	}
}
