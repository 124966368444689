.footer {
    background: #907B64;
    min-height: 80px;
    margin-top: 16px;
    color: #edebe6;
    display: flex;
    align-items: center;
    flex-flow: wrap;
}

.footer div {
    padding: 0 8px;
    margin: 16px;
    display: flex;
    flex-direction: column;
    border-left: 3px solid #E94B3C;
    min-width: 226px;
    line-height: 24px;
}