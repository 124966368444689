.menuContainer {
    position: fixed;
    top: 72px;
    left: 0;
    width: 240px;
    height: 100%;
    background: #FFFFFF;
    overflow-y: auto;
    z-index: 48;
}

.menuContainer nav {
    padding: 12px;
}

.menuContainer nav a {
    min-width: 100%;
    text-decoration: none;
    will-change: background-color;
    color: #000000;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    padding: 9px 24px;
    border: 1px solid #FFFFFF;
    margin: 12px 0;
    display: flex;
    justify-content: space-between;
}

.menuContainer nav a.active {
    border-color: #B36631;
}

.menuContainer nav a.contacts {
    background: #B36631;
    color: #FFFFFF;
    border-color: #B36631;
}

.triangle {
    display: inline-block;
    position: relative;
    color: #B36631;
    transform: rotate(0);
    transition-duration: 0.3s;
    transform-origin: center;
}

.triangleOpened {
    transform: rotate(90deg);
}

.secondaryMenu {
    position: relative;
    transition: linear 1s;
}

.secondaryMenu a {
    padding-left: 45px !important;
}

.hidden {
    height: 0;
    overflow: hidden;
}


