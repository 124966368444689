html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	font-family: "Open Sans", sans-serif;
	font-optical-sizing: auto;
	font-variation-settings: "wdth" 100;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
}
