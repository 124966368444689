.menuButton {
    width: 56px;
    height: 68px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin: 0 16px;
}

.active .topLine {
    transform: rotate(45deg) translate(10px, 10px);
}

.active .middleLine {
    opacity: 0;
}

.active .bottomLine {
    transform: rotate(-45deg) translate(10px, -10px);
}

.topLine, .middleLine, .bottomLine {
    width: 40px;
    height: 2px;
    background: #000000;
    border-radius: 2px;
    margin: 6px 0;
    position: relative;
    transition-duration: 0.3s;
    opacity: 1;
}


